<template>
    <div class="zt-page-content">
        <div class="header">
            <div class="search-bar flex flex-align-center">
                <div style="width:100px;font-size:18px;color:rgba(41,41,41,1);"><b>设计版权</b></div>
                <el-input v-model="query.k" @change="handleChange" placeholder="作品名" style="max-width: 500px;margin-left: 50px;">
                    <el-button @click="search()" slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </div>
            <div class="tab_menu flex">
                <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="menu_item" :class="{'cur':tabIndex == index}">
                    {{item.name}}
                </div>
            </div>
        </div>
        <div class="zt-block flex flex-align-center" style="padding:20px">
            <div class="select-wrap flex flex-align-center">
                <label>风格</label>
                <el-select v-model="query.style_no" @change="handleChange" :popper-append-to-body="false" placeholder="全部" size="small">
                    <el-option v-for="item in config.styles" :key="item.id"
                    :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <!-- <div v-if="tabIndex==0" class="select-wrap flex flex-align-center">
                <label>类目</label>
                <el-cascader :options="config.cats" @change="handleChange" :show-all-levels="false" :props="{value:'id',label:'name',emitPath:false}"
                    v-model="query.cat_id" placeholder="全部" size="small">
                </el-cascader>
            </div> -->
            <div class="select-wrap flex flex-align-center">
                <label>状态</label>
                <el-select v-model="query.stat" @change="handleChange" :popper-append-to-body="false" placeholder="全部" size="small">
                    <el-option v-for="item in config.sale_channel" :key="item.id"
                    :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="select-wrap flex-1 flex">
                <div class="flex-1"></div>
                <div class="head flex">
                    <div class="flex flex-align-center">
                        <span @click="sort('time')" :class="{'active': query.sort_by!='price' }">按时间排序</span>
                        <img @click="sort('up')" 
                            v-if="query.sort_by!='price' && query.sort_order!=1" 
                            src="@/assets/images/factory/sort_down.png">
                        <img @click="sort('down')" 
                            v-if="query.sort_by!='price' && query.sort_order==1" 
                            src="@/assets/images/factory/sort_up.png">
                    </div>
                    <div class="flex flex-align-center">
                        <span @click="sort('price')" :class="{'active': query.sort_by=='price' }">按价格排序</span>
                        <img @click="sort('up')" 
                            v-if="query.sort_by=='price' && query.sort_order!=1" 
                            src="@/assets/images/factory/sort_down.png">
                        <img @click="sort('down')" 
                            v-if="query.sort_by=='price' && query.sort_order==1" 
                            src="@/assets/images/factory/sort_up.png">
                    </div>
                </div>
            </div>
        </div>
        <div style="height:calc(100% - 220px)">
            <Single v-if="tabIndex==0 && hackReset" :query="query"></Single>
            <Series v-if="tabIndex==1 && hackReset" :query="query"></Series>
        </div>
    </div>
</template>

<script>
    import { getCats, getStyles } from '@/service/item';
    import { fetchTree } from '@/util';
    import Single from './Single'; // 单品作品
    import Series from './Series'; // 套系作品
    export default {
        components: {
            Single, Series
        },
        data() {
            return {
                config:{
                    styles: null, // 风格 
                    cats: null, // 类目
                    sale_channel:[
                        { id: 0, name: "全部" },
                        { id: 1, name: "等待我方签约" },
                        { id: 2, name: "等待对方签约" },
                        { id: 6, name: "交易完成" },
                    ]
                },
                query:{
                    k: "",
                    style_no: "", // 风格编号列表
                    // cat_id: null,
                    stat: 0, // 状态列表，0已创建，1买方发起签合同，2买方已签合同，3卖方发起签合同，4卖方已签合同，5买方已付款，6卖方确认收款，7卖方已发货，8买方已收货，9买方已评价，10卖方已评价，11交易完成，21> 卖方已修改商品信息，22库存不足
                    sort_by: "", // 默认按发布时间排序，可以传price按价格排序
                    sort_order: "", // 默认降序，传1表示升序
                    page: 1,
                    pagesize: 10,
                },
                tabList:[
                    {name:"单品作品"},
                    {name:"套系作品"},
                ],
                tabIndex: 0,
                hackReset:true,//刷新子组件
                type: 0, // 作品类型 0单品作品，1套系作品
            }
        },
        // watch: {
        //     query:{
        //         handler() {
        //             this.handleChange();
        //         },
        //         deep: true
        //     }
        // },
        async created() {
            if (this.$route.query.type==1) {
                this.tabIndex = 1;
            }
            this.catList = await getCats();
            this.config.cats = fetchTree(this.catList, null, { name: { dest: 'name' }, id: { dest: 'id' }, parent: 'parentId' });
            console.log(this.config.cats);
            this.config.styles = [{ id:"", name:"全部" }].concat(await getStyles());
        },
        mounted() {},
        methods: {
            gotolink(path){
                this.$router.push(path);
            },
            // 选项卡
            tab(index) {
                this.tabIndex = index;
                if (index == 1) {
                   this.$router.push({ query:{ type: 1} });
                } else {
                    this.$router.push({ query:{ type: 0} });
                }
                console.log(index);
            },
            search() {
                this.hackReset = false;
                this.$nextTick(() => {
                    this.hackReset = true;
                })
            },
            handleChange() {
                console.log("this.query",this.query);
                this.$router.push({ 
                    query:{ 
                        type: this.$route.query.type, 
                        k: this.query.k,
                        style_no: this.query.style_no,
                        stat: this.query.stat,
                        page: 1,
                    } 
                });
                this.hackReset = false;
                this.$nextTick(() => {
                    this.hackReset = true;
                })
            },
            sort(type) { // 排序
                if (type=="time") {
                    this.query.sort_by = "";
                    this.query.sort_order = "";
                } else if (type=="price") {
                    this.query.sort_by = "price";
                    this.query.sort_order = "";
                } else if (type=="up") {
                    this.query.sort_order = 1;
                } else if (type=="down") {
                    this.query.sort_order = "";
                }
                this.hackReset = false;
                this.$nextTick(() => {
                    this.hackReset = true;
                })
            },
            
        }
    }
</script>
<style scoped lang="less">
.header {
    width: 100%;
    padding: 0 60px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px #D1D9E2;
    .search-bar {
        padding: 20px 0 10px;
    }
    .tab_menu {
        .menu_item {
            height: 38px;
            line-height: 38px;
            margin-right: 40px;
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            color: #4D4D4D;
            &.cur {
                color: #24B1EB;
                border-bottom: 2px solid #24B1EB;
            }
        }
    }
}
.select-wrap {
    margin-right: 30px;
    label {
        font-size: 14px;
        color: #292929;
        margin-right: 15px;
    }
    .head {
        font-size: 14px;
        color: #4D4D4D;
        line-height: 20px;
        >div {
            cursor: pointer;
            margin-right: 35px;
            span {
                &.active {
                    color: #24B1EB;
                }
            }
            img {
                width: 14px;
                height: 21px;
                margin-left: 6px;
            }
        }
    }
}
</style>